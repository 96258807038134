import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { Solicitud } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { PaisesService } from '../../../../services/shared/paises.service';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Translators } from '../../../../services/shared/translators.service';
import { Subscription } from 'rxjs';
import { UserEntreUserService } from '@services/shared/user-entre-user.service';
import { MediaOfertaLaboralFileUserPipe } from '@pipes/ofertas-laborales/mediaOfertaLaboralFileUser.Pipe';
import { MatDialog } from '@angular/material/dialog';
import { OfertasVerPdfComponent } from '@components/ofertas-laborales/common/ofertas-ver-pdf/ofertas-ver-pdf.component';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-usuario-solicitudes',
  templateUrl: './usuario-solicitudes.component.html',
  styleUrls: ['./usuario-solicitudes.component.scss']
})
export class UsuariosSolicitudesComponent implements OnInit{

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = [];

    dataSource: any;
    solicitudes: Solicitud [];
    /* Fin Data Table*/

    usuario: User;
    flagLoaded = false;

    idioma: Subscription;

    files: string[] = [];

    isMobile: boolean;


    constructor(
        private solicitudesService: SolicitudesService,
        private actividadesService: ActividadesService,
        private paisesService: PaisesService,
        private authservice: AuthService,
        private router: Router,
        private translatorService: Translators,
        private userEntreUserService: UserEntreUserService,
        public dialog: MatDialog,

    ) {

        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });

    }

    async ngOnInit() {
        await this.checkIfMobile();
        await this.loadDataPage();
    }

    checkIfMobile(): void {
        this.isMobile = window.matchMedia('(max-width: 600px)').matches;
        console.log(' this.isMobile',  this.isMobile);
        if (this.isMobile) {
          this.displayedColumns = ['ver', 'descargar', 'openPdf', 'tramite', '_nro', 'fecha', 'progreso', 'beneficiarioNombre', 'beneficiarioParentezco'];
        } else {
          this.displayedColumns = ['tramite', '_nro', 'fecha', 'progreso', 'beneficiarioNombre', 'beneficiarioParentezco', 'ver', 'descargar', 'openPdf'];
        }
    }

    async verSolicitud(event) {
        const solicitud: Solicitud = event.ver;
        await this.setActividad(solicitud.actividad);
        await this.setPaisDisponible(solicitud.actividad.paisDisponible._id);
        await this.solicitudesService.setSolicitudSeleccionada(solicitud);
        this.router.navigateByUrl('/solicitud');
    }
    // tslint:disable-next-line:variable-name
    async getSolicitudesByUser(_id: string) {
        this.loadDataTable(_id);
    }

    // tslint:disable-next-line:variable-name
    async loadDataTable(_id: string) {
        this.solicitudesService.getSolicitudesByUser(_id).then( ( solds: Solicitud[] ) => {
            this.formatDataTable(solds).then( (data) => {
                console.log(data);
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.flagLoaded = true;
            });
        });
    }

    async getArrayImgsAndFile(adjuntos: string[]) {
        const adjuntosConORM = adjuntos.filter(element => element.includes('ORM'));
        return adjuntosConORM;
    }

    async formatDataTable(solicitudes: Solicitud[]) {
        
        const beneficiaries = await this.userEntreUserService.findGrupoFamiliar(this.usuario._id);

        return Promise.all(solicitudes.map(async (solicitudItem) => {
            const familiarAcargo = beneficiaries.find(beneficiary => beneficiary.adicional?._id === solicitudItem.beneficiario);
            const files = await this.getArrayImgsAndFile(solicitudItem.adjuntos); // Esperar a que se resuelva la promesa
            const elemento = {
                pais: solicitudItem.pais.name_es,
                tramite: solicitudItem.actividad.nombre,
                _nro: solicitudItem._nro,
                fecha: solicitudItem.createdAt,
                progreso: solicitudItem.lastState.numberState,
                ver: solicitudItem,
                descargar: solicitudItem,
                indentificacion: solicitudItem,
                beneficiarioNombre: familiarAcargo?.adicional?.fullName || solicitudItem?.usuario?.fullName,
                beneficiarioParentezco: familiarAcargo?.parentesco || 'TITULAR',
                file: files
            };

            return elemento;
        }));
    }


    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    setActividad(actividad: Actividad) {
        return this.actividadesService.setActividadSeleccionada(actividad);
    }

    async setPaisDisponible(id: string) {
       await this.paisesService.getPaisDisponibleFinOne(id).then( (pais: PaisDisponible) => {
           return this.paisesService.setPaisSeleccionado(pais);
       });
    }

    async loadDataPage() {
        await this.getSolicitudesByUser((this.usuario = await this.authservice.getUserLogon())._id);
        return;
    }

    download(idUsuario: string, adjunto: string, nombre: string) {
        FileSaver.saveAs(this.mediaOfertaLaboralFileUser(idUsuario, adjunto), nombre);
    }

    mediaOfertaLaboralFileUser(idUsuario: string, adjunto: string) {
        const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
        return mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);
    }

    /* Modal PDF  - Carga PDF */
    openDialogPdf(idUsuario: string, adjunto: string, tituloDocumento: string): void {

    const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
    const path = mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);

    const dialogRef = this.dialog.open(OfertasVerPdfComponent, {
        width: '800px',
        height: '800px',
        disableClose: true,
        data: {
                path,
                tituloDocumento,
            }
    });

    dialogRef.afterClosed().subscribe(async result => {
    });
 }

}
